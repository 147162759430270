import classNames from 'classnames';
import React, { FC, useEffect } from 'react';

import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';
import { useQuestionLabels } from 'modules/budget/hooks/useQuestionLabels';
import { useQuestionOptions } from 'modules/budget/hooks/useQuestionOptions';
import { Article } from 'modules/budget/models/Article';
import { ArticleCollectionTypes } from 'modules/budget/models/Article/types';
import { dismissServiceId } from 'modules/budget/store/data';

import { LoadingItems } from '../../commons/LoadingItems';
import { OptionSelector } from '../../commons/OptionSelector';
import { Classable } from '../../types/helpers';

interface ItemSelectorComponent extends Classable {
  onChange: (articles: Article[]) => void;
  question: BudgetQuestionCodes;
  collectionType?: ArticleCollectionTypes;
  multiselect?: boolean;
  value: Article[];
  dismissService?: boolean;
}

export const ItemSelector: FC<ItemSelectorComponent> = (
  {
    onChange,
    question,
    className,
    collectionType = ArticleCollectionTypes.ARTICLE,
    multiselect,
    value,
    dismissService,
  },
) => {
  const { dismiss } = useQuestionLabels();
  const { items, fetchOptions, loading } = useQuestionOptions();

  useEffect(
    () => {
      fetchOptions(question, collectionType);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionType, question],
  );

  return loading
    ? (<LoadingItems className={className} />)
    : (
      <OptionSelector
        className={classNames('f-item-selector', className)}
        dismissService={dismissService}
        items={[
          ...[
            BudgetQuestionCodes.Q11,
            BudgetQuestionCodes.Q24,
            BudgetQuestionCodes.Q42,
            BudgetQuestionCodes.Q44,
          ].includes(question)
          || (
            [
              BudgetQuestionCodes.Q31,
            ].includes(question)
            && collectionType !== ArticleCollectionTypes.ARTICLE
          )
            ? [{
              code: dismissServiceId,
              description: dismiss(question),
            }]
            : [],
          ...items,
        ]}
        multiselect={multiselect}
        value={value}
        onChange={(articles) => {
          onChange(articles);
        }}
      />
    );
};
