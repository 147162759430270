import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProvidedAuth } from './useProvidedAuth';

type WorkshopTvViewHook = {
  isTvView: boolean | undefined;
  id: string;
  type: Types;
}

export enum Centers {
  GRANOLLERS = 'GRANOLLERS_WORKSHOP',
  MATARO = 'MATARO_WORKSHOP',
}

export enum Types {
  CEMETERY = 'CEMETERY',
  WORKSHOP = 'WORKSHOP',
}

export type CenterType = 'GRANOLLERS' | 'MATARO';

type PageParams = {
  id: string | CenterType;
  type: Types;
};

export const useWorkshopTvView = (): WorkshopTvViewHook => {
  const { user } = useProvidedAuth();
  const { id, type } = useParams<PageParams>();
  const [isTvView, setisTVView] = useState<boolean>();

  useEffect(() => {
    if (!user && !!id && !!type) {
      setisTVView(true);
    } else if (user) {
      setisTVView(false);
    }
  }, [user, id, type]);

  return {
    id,
    isTvView,
    type,
  };
};
