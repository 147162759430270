import { ReactElement, ReactNode } from 'react';
import { toast } from 'react-toastify';

const options = {
  position: toast.POSITION.TOP_CENTER,
  progress: 0,
};

/**
 * Shows a Success toast
 * @param {string} message text to show
 */
export const showSuccessToast = (message: ReactElement | ReactNode): void => {
  toast.success(message, {
    ...options,
    className: 'toast-success',
  });
};

/**
 * Shows a Error toast
 * @param {string} message text to show
 */
export const showErrorToast = (message: ReactElement | ReactNode): void => {
  toast.error(message, {
    ...options,
    className: 'toast-error',
  });
};

/**
 * Shows a Warning toast
 * @param {string} message text to show
 */
export const showWarningToast = (message: ReactElement | ReactNode): void => {
  toast.warn(message, {
    ...options,
    className: 'toast-warn',
  });
};

/**
 * Shows a Info toast
 * @param {string} message text to show
 */
export const showInfoToast = (message: ReactElement | ReactNode, customOptions = options): void => {
  toast.info(message, {
    ...customOptions,
    className: 'toast-info',
  });
};

/**
 * Shows a Default toast
 * @param {string} message text to show
 */
export const showDefaultToast = (message: ReactElement | ReactNode): void => {
  toast(message, {
    ...options,
    className: 'toast-default',
  });
};
