import { FormikProps, getIn } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IdentifierSelector, { IdentifierTypes } from 'components/base/IdentifierSelector';
import Input from 'components/base/Input';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import { useEnv } from 'hooks/useEnv';
import { i18n } from 'i18n';
import { CityType } from 'models/MasterData';
import { PersonalDataDocumentDto } from 'models/PersonalDataDocumentDto';
import { SaveRecord } from 'models/Record';
import { IaDocumentScanner } from 'modules/ia/components/document-scanner';
import { useIA } from 'modules/ia/hooks/useIa';

import { mapBase64ToPersonalDataDocument } from './mapBase64ToPersonalDataDocument';
import { mapDocumentTypeCode } from './mapDocumentTypeCode';

type DeclarantFieldsProps = {
  createMode?: boolean;
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};

const DeclarantFields: FC<DeclarantFieldsProps> = ({
  createMode,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values, setValues,
  },
}) => {
  const { iaEnableScanDocument } = useEnv();
  const { isEnabledDocumentType } = useIA();
  const
    [documentType,
      setdocumentType] = useState<IdentifierTypes | null>(IdentifierTypes.IDENTIFIER);

  const { t } = useTranslation();
  return (
    <fieldset>
      {createMode && <legend>{t('record.steps.declarant')}</legend>}
      <Input
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'declarantData.name')}
        id="declarantData.name"
        name="declarantData.name"
        placeholder={i18n.t('common.fullName')}
        type="text"
        value={values.declarantData?.name || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('declarantData.name', val)}
      />
      {!createMode && (
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'declarantData.deceasedKinship')}
          id="declarantData.deceasedKinship"
          name="declarantData.deceasedKinship"
          placeholder={i18n.t('common.kinship')}
          type="text"
          value={values.declarantData?.deceasedKinship || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('declarantData.deceasedKinship', val)}
        />
      )}
      <div style={{ display: 'flex' }}>
        <IdentifierSelector
          disabled={disabled || isSubmitting}
          errors={{
            cif: getIn(errors, 'declarantData.cif'),
            identifier: getIn(errors, 'declarantData.identifier'),
            nie: getIn(errors, 'declarantData.nie'),
            passport: getIn(errors, 'declarantData.passport'),
          }}
          id="declarantData.identifier"
          name="declarantData.identifier"
          placeholder={i18n.t('common.identifierDocument')}
          style={{
            width: '100%',
          }}
          type="text"
          values={{
            cif: values.declarantData?.cif || undefined,
            identifier: values.declarantData?.identifier || undefined,
            nie: values.declarantData?.nie || undefined,
            passport: values.declarantData?.passport || undefined,
          }}
          onBlur={handleBlur}
          onChange={(key, val) => {
            setFieldValue(`declarantData.${key}`, val);
            setFieldValue('declarantDataDocumentDto', null);
          }}
          onSelectChange={(v) => {
            if (v && isEnabledDocumentType(v as IdentifierTypes)) {
              setdocumentType(v as IdentifierTypes);
            } else {
              setdocumentType(null);
            }
          }}
        />
        {documentType && iaEnableScanDocument() && (
          <IaDocumentScanner
            documentType={documentType}
            infoFormlayout="declarant"
            onConfirm={(iaData, fileType) => {
              const doc: PersonalDataDocumentDto = {
                backDocument: iaData.backCapture ? mapBase64ToPersonalDataDocument(iaData.backCapture, fileType) : '',
                documentName: `DECLARANT_${mapDocumentTypeCode(documentType)}_${iaData.idCard?.toUpperCase()}`,
                frontDocument: iaData.frontCapture ? mapBase64ToPersonalDataDocument(iaData.frontCapture, fileType) : '',
                mediaType: fileType,
                type: documentType.toUpperCase(),
              };

              let declarantDataAddress = values.declarantData?.address;
              if (iaData.address.city
                || iaData.address.country
                || iaData.address.province
                || iaData.address.street
              ) {
                declarantDataAddress = {
                  ...declarantDataAddress,
                  city: iaData.address.city || values.declarantData?.address?.city,
                  country: iaData.address.country || values.declarantData?.address?.country,
                  province: iaData.address.province || values.declarantData?.address?.province,
                  streetName: iaData.address.street || values.declarantData?.address?.streetName,
                };
              }

              setValues({
                ...values,
                declarantData: {
                  ...values.declarantData,
                  address: declarantDataAddress,
                  [documentType]: iaData.idCard,
                  name: `${iaData.name || ''} ${iaData.firstSurname || ''} ${iaData.secondSurname || ''}`,
                },
                declarantDataDocumentDto: doc,
              });
            }}
          />
        )}
      </div>
      {!createMode && (
        <SelectCountryProvinceCity
          cityProps={{
            error: getIn(errors, 'declarantData.address.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'declarantData.address.c',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('declarantData.address.city', val),
            onChangeFull: (val?: CityType) => setFieldValue(
              'declarantData.address.postalCode',
              val?.postalCode,
            ),
            placeholder: i18n.t('common.city'),
            value: values.declarantData?.address?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'declarantData.address.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'declarantData.address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('declarantData.address.country', val),
            placeholder: i18n.t('common.country'),
            value: values.declarantData?.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'declarantData.address.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'declarantData.address.p',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('declarantData.address.province', val),
            placeholder: i18n.t('common.province'),
            value: values.declarantData?.address?.province || '',
          }}
        />
      )}
      {!createMode && (
        <Textarea
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'declarantData.address.streetName')}
          id="declarantData.address.streetName"
          name="declarantData.address.streetName"
          placeholder={i18n.t('common.address')}
          value={values.declarantData?.address?.streetName || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('declarantData.address.streetName', val)}
        />
      )}
      {!createMode && (
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'declarantData.address.postalCode')}
          id="declarantData.address.postalCode"
          name="declarantData.address.postalCode"
          placeholder={i18n.t('common.postalCode')}
          type="text"
          value={values.declarantData?.address?.postalCode || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('declarantData.address.postalCode', val)}
        />
      )}
      <Input
        disabled={disabled || isSubmitting}
        error={getIn(errors, 'declarantData.phone')}
        id="declarantData.phone"
        name="declarantData.phone"
        placeholder={i18n.t('common.phone')}
        type="text"
        value={values.declarantData?.phone || ''}
        onBlur={handleBlur}
        onChange={(val) => setFieldValue('declarantData.phone', val)}
      />
      {!createMode && (
        <Input
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'declarantData.email')}
          id="declarantData.email"
          name="declarantData.email"
          placeholder={i18n.t('common.email')}
          type="text"
          value={values.declarantData?.email || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('declarantData.email', val)}
        />
      )}
      {!createMode && (
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'declarantData.comment')}
          id="declarantData.comment"
          name="declarantData.comment"
          placeholder={i18n.t('common.observations')}
          value={values.declarantData?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('declarantData.comment', val)}
        />
      )}
    </fieldset>
  );
};
export default DeclarantFields;
