import React, { Fragment, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import config from 'config';

import { checkHaveThisPermissions, checkPermission } from 'utils/permissions';

import CustomIcon from 'components/base/CustomIcon';
import { FeatherIconTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import { TableActionsProps } from 'components/base/Table';
import { TableColumn } from 'components/base/Table/types';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { Ip, SearchIpProps } from 'models/Ip';

const IpsTable: FC = () => {
  const { t } = useTranslation();
  const { user } = useProvidedAuth();
  const columns = useMemo(
    (): TableColumn<Ip>[] => [
      { accessor: 'id', Header: 'Id', hidden: true },
      {
        accessor: 'name',
        Header: `${t('common.name')}`,
        sortable: true,
      },
      {
        accessor: 'ip',
        Header: `${t('ip.address')}`,
        sortable: true,
      },
    ],
    [t],
  );

  const getActions = (row: Ip) => {
    const actions: TableActionsProps = {};
    if (
      checkPermission(SimpleUserRequestPermissionNames.USER_READ, user?.role.permissions)
      && !checkPermission(SimpleUserRequestPermissionNames.USER_WRITE, user?.role.permissions)
    ) {
      actions.see = {
        icon: <CustomIcon icon={FeatherIconTypes.EYE} />,
        tooltipCaption: t('common.see'),
        url: `${config.url.ips}/${row.id}`,
      };
    }

    if (
      checkHaveThisPermissions(
        [SimpleUserRequestPermissionNames.USER_READ, SimpleUserRequestPermissionNames.USER_WRITE],
        user?.role.permissions,
      )
    ) {
      actions.edit = {
        url: `${config.url.ips}/${row.id}/edit`,
      };
    }

    return actions;
  };

  return (
    <Fragment>
      <FilteredPage<SearchIpProps, Ip>
        actions={getActions}
        apiCall={config.apiFunus.ips.getIps}
        columns={columns}
        create={{
          title: t('ip.new'),
          url: config.url.newIp,
        }}
        fields={() => []}
        initialValues={{}}
        showFilters={false}
        text={{
          search: t('ip.search'),
          title: t('ip.title'),
        }}
      />
    </Fragment>
  );
};

export default IpsTable;
