import { FunusMovementTypes, MasterServiceDtoType } from 'config/apiFunus/generated/data-contracts';
import Address from 'models/Address';
import Client from 'models/Client';
import { IMasterDataDto } from 'models/MasterData';
import { WorkOrderTypes } from 'models/MasterWorkOrder';
import { DeceasedData, Declarant } from 'models/Record';

import { DateType, MasterDataDtoCode, Observation } from './dataTypes';

export enum DeceasedLocation {
  chamber = 'CAMARA',
  center = 'CENTRO',
}

export enum FinalDestination {
  cemetery = 'CEMENTERIO',
  crematory = 'CREMATORIO',
}

export enum MasterDataTypes {
  ABORTION_TYPE = 'abortion/type',
  AIRPORT = 'airport',
  ASHES_DESTINATION = 'ashes/destination',
  CENTER = 'center',
  CEREMONY = 'ceremony',
  CITY = 'CITY',
  CONCESSION_TYPE = 'concession/type',
  COUNTRY = 'country',
  DEATH_TYPE = 'death/type',
  FUNERARY_ORIGIN = 'originFuneralHome',
  GENDER = 'gender',
  GROUP_CAUSE = 'group/cause',
  INSURANCE = 'insurance',
  MARITAL_STATUS = 'maritalStatus',
  NICHE_TYPE = 'niche/type',
  OWNER = 'owner',
  PROVINCE = 'PROVINCE',
  RELIQUARY = 'reliquary',
  SANITARY_PRACTICE = 'sanitaryPractice',
  THANATOLOGIST = 'thanatologist',
  THANATOPRACTOR = 'thanatopractor',
  URN = 'urn',
}

export enum TransferType {
  CAR = 'CAR',
  PLANE = 'PLANE',
}

export enum CoffinTypes {
  opened = 'ABIERTO',
  closed = 'CERRADO',
}

export enum FittedType {
  coffin = 'WITH_COFFIN',
  noCoffin = 'WITHOUT_COFFIN'
}

export type PostCeremony = {
  address?: Address;
  comment?: string;
  date?: Date;
  location?: string;
  time?: Date;
  type?: string;
};

export type CeremonyService = {
  id?: string;
  address?: Address;
  ceremonyAddress?: Address;
  ceremonyDate?: Date;
  ceremonyComment?: string;
  ceremonyTime?: Date;
  comment?: string;
  location?: string;
  postCeremonies?: PostCeremony[];
  pressCommunication?: boolean;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ceremonial?: any;
};

export type CremationService = {
  id?: string;
  address?: Address;
  ashesDeliveryDate?: Date;
  ashesDeliveryHandler?: string;
  ashesDestiny?: string;
  ashesReceiver?: string;
  ashesReceiverCif?: string;
  ashesReceiverIdentifier?: string;
  ashesReceiverNie?: string;
  ashesReceiverPassport?: string;
  comment?: string;
  cremationDate?: Date;
  cremationTime?: Date;
  crematorium?: string;
  deposit?: string;
  entryDate?: Date;
  entryTime?: Date;
  familyPresence?: boolean;
  judicial?: boolean;
  operator?: string;
  originMortuary?: string;
  oven?: string;
  owner?: string;
  zincBox?: boolean;
  work?: boolean;
  cremationDocumentation?: boolean;
  cremationDocumentationUser: string;
  cremationDocumentationDatetime: Date;
};

export type ConcessionData = {
  beneficiary?: string;
  beneficiaryAddress?: Address;
  beneficiaryCif?: string;
  beneficiaryIdentifier?: string;
  beneficiaryNie?: string;
  beneficiaryPassport?: string;
  comment?: string;
  holder?: string;
  holderAddress?: Address;
  holderCif?: string;
  holderIdentifier?: string;
  holderNie?: string;
  holderPassport?: string;
  temporality?: string;
  type?: string;
};

export type DepositService = {
  id?: string;
  address?: Address;
  comment?: string;
  deposit?: string;
  entryDate?: Date;
  entryTime?: Date;
  exitDate?: Date;
  exitTime?: Date;
};

export type IntermentService = {
  id?: string;
  cemetery?: string;
  concession?: ConcessionData;
  familiarPresentPreparation?: boolean;
  intermentAddress?: Address;
  intermentComment?: string;
  intermentDate?: Date;
  intermentTime?: Date;
  judicial?: boolean;
  niche?: string;
  nicheNumber?: number;
  nicheSection?: string;
  nicheTier?: number;
  preparationDate?: Date;
  preparationTime?: Date;
  takeMeasurementsPhoto?: boolean;
  zincBox?: boolean;
  ashes?: boolean;
  cemeteryCoffin?: boolean;
  cemeteryAshes?: boolean;
};

export type Applicant = {
  address?: Address;
  cif?: string;
  declarant?: boolean;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  phone?: string;
  signature?: string;
};

export type SanitaryPractice = {
  address?: Address;
  atHome?: boolean;
  endDate?: Date;
  endTime?: Date;
  location?: string;
  startDate?: Date;
  startTime?: Date;
  thanatologist?: string;
  thanatopractor?: string;
  type?: string;
};

export type PreparationService = {
  id?: string;
  aconditioning?: boolean;
  applicant?: Applicant;
  clothing?: boolean;
  clothingComment?: string;
  coffin?: boolean;
  coffinPreparationDetail?: string;
  comment?: string;
  deceasedLocation?: DeceasedLocation;
  finalDestination?: FinalDestination;
  hairstyle?: boolean;
  hairstyleComment?: string;
  makeup?: boolean;
  makeupComment?: string;
  sanitaryPractices?: SanitaryPractice[];
  pacemaker?: boolean;
  personalItems?: string;
  shaved?: boolean;
  shavedComment?: string;
  creu?: boolean;
  creuComment?: string;
  cover?: boolean;
  coverComment?: string;
  readOnly?: boolean;
};

export type VigilService = {
  id?: string;
  address?: Address;
  comment?: string;
  entryDate?: Date;
  entryTime?: Date;
  exitDate?: Date;
  exitTime?: Date;
  externalNumber?: string;
  location?: string;
  vigilAtHome?: boolean;
  vigilRoom?: string;
  vigilRemarks?: string;
};

export interface NormalizedType {
  [key: string]: IMasterDataDto;
}

export interface NormalizedEntity<T> {
  [key: string]: T;
}

export interface MasterAndNormalized {
  masterData: IMasterDataDto[];
  normalized: NormalizedType;
}

export interface MasterAndNormalizedType<T> {
  masterData: T[];
  normalized: NormalizedEntity<T>;
}

export type Flight = {
  agency?: string;
  arrivalDate?: Date;
  arrivalTime?: Date;
  company?: string;
  connectionNumber?: string;
  deliveryDate?: Date;
  deliveryTime?: Date;
  departureAirport?: string;
  departureDate?: Date;
  departureTime?: Date;
  destinationAirport?: string;
  flightNumber?: string;
};

export type PlaneTransport = {
  flights?: Flight[];
  measures?: string;
  weight?: string;
};

export type RoadTransport = {
  borderCrossing?: string;
  destination?: string;
  estimatedArrivalDate?: Date;
  estimatedArrivalTime?: Date;
  exitDate?: Date;
  exitTime?: Date;
};

export type TransferService = {
  id?: string;
  comment?: string;
  planeTransport?: PlaneTransport;
  recipientAddress?: Address;
  recipientEmail?: string;
  recipientName?: string;
  recipientPhone?: string;
  roadTransport?: RoadTransport;
  type?: TransferType;
};

export type MovementService = {
  type: FunusMovementTypes;
  address?: Address;
  location?: MasterDataDtoCode;
  vigilPlace?: string;
  date?: DateType;
  time?: DateType;
  comment?: Observation
};

export type SaveService = {
  ceremonyServiceDto?: CeremonyService;
  clients?: Client[];
  cremationServiceDto?: CremationService;
  deceasedData?: DeceasedData;
  declarantData?: Declarant;
  depositServiceDto?: DepositService;
  erpId?: number;
  idRecord?: number;
  id?: number;
  intermentServiceDto?: IntermentService;
  preparationServiceDto?: PreparationService;
  transferServiceDto?: TransferService;
  vigilServiceDto?: VigilService;
  movementServiceDto?: MovementService;
  defaultArticleDate?: Date;
  defaultArticleTime?: Date;
};

export type ServiceForm = (
  | CeremonyService
  | CremationService
  | IntermentService
  | PreparationService
  | SanitaryPractice
  | TransferService
  | VigilService
) & {
  sorting: number;
  workOrders: WorkOrderTypes[];
};

type MasterService = {
  type: MasterServiceDtoType;
  sorting: number;
  workOrders: WorkOrderTypes[];
};

export default MasterService;
