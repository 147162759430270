import React from 'react';
import { Route } from 'react-router-dom';

import config from 'config';

import ServicesPage from 'components/pages/adviser/Services';
import EnquiryForm from 'components/pages/enquiry/Form';
import EnquiryTable from 'components/pages/enquiry/Table';
import IpForm from 'components/pages/ips/Form';
import IpTable from 'components/pages/ips/Table';
import WorkshopPage from 'components/pages/operator/Workshop';
import RecordForm from 'components/pages/record/Form';
import RecordTable from 'components/pages/record/Table';
import RoleForm from 'components/pages/roles/Form';
import RolesTable from 'components/pages/roles/Table';
import UserForm from 'components/pages/user/Form';
import UserTable from 'components/pages/user/Table';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { Permission } from 'models/UserRole';

import EnquiryDashboardPage from '../enquiry/Dashboard';
import { Information } from '../Information';
import { AgendaPage, AgendaPageWithoutInfo } from '../manager/Agenda';
import { CalendarPage, CalendarPageWithoutInfo } from '../manager/Calendar';

type InnerRoute = {
  read: JSX.Element[];
  write: JSX.Element[];
  permissionsAllowed: SimpleUserRequestPermissionNames[];
};

const adviser: InnerRoute = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.ADVICE_READ,
    SimpleUserRequestPermissionNames.ADVICE_WRITE,
    SimpleUserRequestPermissionNames.ADVICE_ADD,
  ],
  read: [
    <Route
      key={config.url.services}
      component={ServicesPage}
      path={`${config.url.services}/:id`}
      exact
    />,
    <Route
      key={config.url.calendar}
      component={CalendarPage}
      path={config.url.calendar}
      exact
    />,
    <Route
      key={config.url.calendarNoInfo}
      component={CalendarPageWithoutInfo}
      path={config.url.calendarNoInfo}
      exact
    />,
  ],
  write: [
    <Route
      key={config.url.services}
      component={ServicesPage}
      path={`${config.url.services}/:id`}
      exact
    />,
    <Route
      key={config.url.agenda}
      component={AgendaPage}
      path={config.url.agenda}
      exact
    />,
    <Route
      key={config.url.agendaNoInfo}
      component={AgendaPageWithoutInfo}
      path={config.url.agendaNoInfo}
      exact
    />,
  ],
};

const enquiry = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.ENQUIRY_READ,
    SimpleUserRequestPermissionNames.ENQUIRY_WRITE,
    SimpleUserRequestPermissionNames.ENQUIRY_ADD,
  ],
  read: [
    <Route
      key={config.url.enquiries}
      component={EnquiryTable}
      path={config.url.enquiries}
      exact
    />,
    <Route
      key={config.url.enquiriesDashboard}
      component={EnquiryDashboardPage}
      path={config.url.enquiriesDashboard}
      exact
    />,
    <Route
      key={config.url.newEnquiry}
      component={EnquiryForm}
      path={[`${config.url.enquiries}/:id`]}
      exact
    />,
  ],
  write: [
    <Route
      key={config.url.newEnquiry}
      component={EnquiryForm}
      path={[config.url.newEnquiry, `${config.url.enquiries}/:id/:action`]}
      exact
    />,
  ],
};

const users: InnerRoute = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.USER_READ,
    SimpleUserRequestPermissionNames.USER_WRITE,
    SimpleUserRequestPermissionNames.USER_ADD,
  ],
  read: [
    <Route
      key={config.url.roles}
      component={RolesTable}
      path={config.url.roles}
      exact
    />,
    <Route
      key="showRoles"
      component={RoleForm}
      path={[`${config.url.roles}/:id`]}
      exact
    />,
    <Route
      key={config.url.users}
      component={UserTable}
      path={config.url.users}
      exact
    />,
    <Route
      key={config.url.ips}
      component={IpTable}
      path={config.url.ips}
      exact
    />,
    <Route
      key={config.url.newUsers}
      component={UserForm}
      path={[`${config.url.users}/:id`]}
      exact
    />,
  ],

  write: [
    <Route
      key={config.url.newUsers}
      component={UserForm}
      path={[config.url.newUsers, `${config.url.users}/:id/:mode`]}
      exact
    />,
    <Route
      key="other route"
      component={RoleForm}
      path={[
        config.url.newRole,
        `${config.url.roles}/:id/edit`,
        `${config.url.roles}/:id/clone`,
      ]}
      exact
    />,
    <Route
      key={config.url.newIp}
      component={IpForm}
      path={[config.url.newIp, `${config.url.ips}/:id/edit`]}
      exact
    />,
  ],
};

const records = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.RECORD_READ,
    SimpleUserRequestPermissionNames.RECORD_WRITE,
    SimpleUserRequestPermissionNames.RECORD_ADD,
    SimpleUserRequestPermissionNames.RECORD_ADD_NOTICE,
  ],
  read: [
    <Route
      key={config.url.records}
      component={RecordTable}
      path={config.url.records}
      exact
    />,
    <Route
      key={config.url.newRecord}
      component={RecordForm}
      path={[config.url.newRecord, `${config.url.records}/:id`]}
      exact
    />,
  ],
  write: [
    <Route
      key={config.url.newRecord}
      component={RecordForm}
      path={[config.url.newRecord, `${config.url.records}/:id/edit`]}
      exact
    />,
  ],
};

const workshop = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_READ,
    SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_READ,
  ],
  read: [
    <Route
      key={config.url.workshop}
      component={WorkshopPage}
      path={[config.url.workshop, `${config.url.workshop}/:id`]}
      exact
    />,
  ],
  write: [],
};

const manager = {
  permissionsAllowed: [SimpleUserRequestPermissionNames.RECORD_ASSIGN],
  read: [
    <Route
      key={config.url.calendar}
      component={CalendarPage}
      path={config.url.calendar}
      exact
    />,
    <Route
      key={config.url.calendarNoInfo}
      component={CalendarPageWithoutInfo}
      path={config.url.calendarNoInfo}
      exact
    />,
  ],
  write: [],
};

const agenda = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.WORK_ORDER_CEMETERY_ADD,
    SimpleUserRequestPermissionNames.WORK_ORDER_WORKSHOP_ADD,
  ],
  read: [],
  write: [
    <Route
      key={config.url.agenda}
      component={AgendaPage}
      path={config.url.agenda}
      exact
    />,
    <Route
      key={config.url.agendaNoInfo}
      component={AgendaPageWithoutInfo}
      path={config.url.agendaNoInfo}
      exact
    />,
  ],
};
const information = {
  permissionsAllowed: [
    SimpleUserRequestPermissionNames.ADVICE_INFO,
  ],
  read: [
    <Route
      key={config.url.information}
      component={Information}
      path={config.url.information}
      exact
    />,
  ],
  write: [],
};

const routesByPermissions = (
  permissions: Permission[] | undefined,
): JSX.Element[] | null => {
  const allRoutes = [adviser, enquiry, records, workshop, manager, agenda, information];
  let routeAllowed: JSX.Element[] = [
    <Route
      key={`${config.url.newUser}-with-token`}
      component={UserForm}
      path={[`${config.url.newUser}/:id`]}
      exact
    />,
  ];
  allRoutes.forEach((route) => {
    permissions?.forEach((permission) => {
      const checkPermission: boolean = route.permissionsAllowed.includes(
        permission.name as SimpleUserRequestPermissionNames,
      );

      if (
        checkPermission
        && (permission.name.includes('_READ')
          || permission.name.includes('_ASSIGN')
          || permission.name.includes('_INFO'))
      ) {
        routeAllowed = [...routeAllowed, ...route.read];
      } else if (
        checkPermission
        && (permission.name.includes('_WRITE') || permission.name.includes('_ADD'))
      ) {
        routeAllowed = [...routeAllowed, ...route.write];
      }
    });
  });
  return routeAllowed;
};

export default routesByPermissions;

export const routesAdmin = (): JSX.Element[] => [...users.read, ...users.write];
