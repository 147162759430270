import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import config from 'config';

import Loader from 'components/base/Loader';

import Login from 'components/pages/Login';
import WorkshopPage from 'components/pages/operator/Workshop';
import ForgotPasswordPage from 'components/pages/user/Forgot';
import NewPasswordPage from 'components/pages/user/NewPassword';

import { useProvidedAuth } from 'hooks/useProvidedAuth';

type AuthorizedProps = {
  children: JSX.Element;
};

const Authorized: FC<AuthorizedProps> = ({ children }) => {
  const { loading, user } = useProvidedAuth();

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <Switch>
        <Route
          component={NewPasswordPage}
          path={[`${config.url.forgot}/:token`, `${config.url.newUser}/:token`]}
          exact
        />
        <Route component={ForgotPasswordPage} path={config.url.forgot} exact />
        <Route
          key={config.url.workshop}
          component={WorkshopPage}
          path={[config.url.workshop, `${config.url.workshop}/:id/:type`]}
          exact
        />
        <Route component={Login} />
      </Switch>
    );
  }

  return children;
};

export default Authorized;
