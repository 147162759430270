import { isNull } from 'utils/helpers';

import { BudgetQuestionCodes } from '../data/budgetQuestionCodes';
import { CeremonyTypes } from '../data/ceremonyTypeCodes';
import { ArticleCollectionTypes } from '../models/Article/types';
import { ArticleCollection } from '../models/ArticlesCollection';
import { IArticlesCollection } from '../models/ArticlesCollection/types';
import { BudgetLocation } from '../models/BudgetLocation';
import { BudgetQuestion } from '../models/BudgetQuestion';
import { IBudgetQuestion, BudgetResponseCodes, BudgetQuestionTypes } from '../models/BudgetQuestion/types';
import { AnswerQuestionResponse } from '../store/budgetActions/types';
import { BudgetQuestionCollection } from '../store/budgetReducer/types';
import { ServiceTypes, NicheOwnershipTypes } from '../store/data';

export class BudgetHelper {
  constructor(data: Array<BudgetQuestionCodes>) {
    this.questions = data;
  }

  private questions: Array<BudgetQuestionCodes>

  public nextQuestion(
    current: BudgetQuestionCodes,
    questions: BudgetQuestionCollection,
    cabreCollection = false,
  ): BudgetQuestionCodes {
    const currentList = this.availableQuestions(questions, cabreCollection);
    const currentIndex = currentList.findIndex((i) => i === current);
    const nextIndex = currentIndex < currentList.length - 1 ? currentIndex + 1 : currentIndex;
    return currentList[nextIndex];
  }

  public prevQuestion(
    current: BudgetQuestionCodes,
    questions: BudgetQuestionCollection,
    cabreCollection = false,
  ): BudgetQuestionCodes {
    const currentList = this.availableQuestions(questions, cabreCollection);
    const currentIndex = currentList.findIndex((i) => i === current);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    return currentList[prevIndex];
  }

  public getUpdatedQuestionCollection(
    currentCollection: BudgetQuestionCollection,
    questionCode: BudgetQuestionCodes,
    response?: AnswerQuestionResponse,
  ): BudgetQuestionCollection {
    const {
      service, id, yesNo,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = response as any || {};

    const {
      address,
      location,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = response as any || {
      address: null,
      location: null,
    };

    const {
      articles,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = response as any || { articles: [] };

    const {
      atHome,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = response as any || { atHome: false };

    const questions: Record<string, () => BudgetQuestionCollection> = {
      [BudgetQuestionCodes.Q1]: () => {
        const responses: Record<string, BudgetResponseCodes> = {
          [ServiceTypes.DEFUNCIO]: BudgetResponseCodes.R1_1,
          [ServiceTypes.AMPUTACIO]: BudgetResponseCodes.R1_2,
          [ServiceTypes.AVORTAMENT]: BudgetResponseCodes.R1_3,
          [ServiceTypes.DESPULLES]: BudgetResponseCodes.R1_4,
          [ServiceTypes.INHUMACIO_CENDRES]: BudgetResponseCodes.R1_5,
        };

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q1]: {
            ...currentCollection[BudgetQuestionCodes.Q1],
            code: questionCode,
            response: responses[service],
          },
          [BudgetQuestionCodes.Q2]: service === ServiceTypes.DEFUNCIO
            ? {
              code: BudgetQuestionCodes.Q2,
              response: BudgetResponseCodes.R2_1,
            }
            : null,
        };
      },
      [BudgetQuestionCodes.Q2]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q2]: {
          ...currentCollection[BudgetQuestionCodes.Q2],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R2_1
            : BudgetResponseCodes.R2_2,
        },
      }),
      [BudgetQuestionCodes.Q3]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q3]: {
          ...currentCollection[BudgetQuestionCodes.Q3],
          atHome,
          code: questionCode,
          location: {
            city: address?.city,
            country: address?.country,
            location: location?.id,
            province: address?.province,
          },
          questionType: this.getQuestionType(questionCode),
          response: address?.work ? BudgetResponseCodes.R3_1 : BudgetResponseCodes.R3_2,
        },
      }),
      [BudgetQuestionCodes.Q4]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q4]: {
          ...currentCollection[BudgetQuestionCodes.Q4],
          code: questionCode,
          insuranceCode: id,
          questionType: this.getQuestionType(questionCode),
          response: id
            ? BudgetResponseCodes.R4_2
            : BudgetResponseCodes.R4_1,
        },
      }),
      [BudgetQuestionCodes.Q5]: () => {
        const wantService = address && location;
        let responseCode: BudgetResponseCodes = BudgetResponseCodes.R5_4;
        let locationData;

        if (wantService) {
          locationData = {
            city: address?.city,
            country: address?.country,
            location: location?.id,
            province: address?.province,
          };
          if (address?.work) {
            responseCode = location?.work
              ? BudgetResponseCodes.R5_1
              : BudgetResponseCodes.R5_2;
          } else {
            responseCode = BudgetResponseCodes.R5_3;
          }
        }

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q5]: {
            ...currentCollection[BudgetQuestionCodes.Q5],
            code: questionCode,
            location: locationData,
            questionType: this.getQuestionType(questionCode),
            response: responseCode,
          },
        };
      },
      [BudgetQuestionCodes.Q6]: () => {
        const wantService = address && location;
        let responseCode: BudgetResponseCodes = BudgetResponseCodes.R6_4;
        let locationData;

        if (wantService) {
          locationData = {
            city: address?.city,
            country: address?.country,
            location: location?.id,
            province: address?.province,
          };

          if (address?.work) {
            responseCode = location?.work
              ? BudgetResponseCodes.R6_1
              : BudgetResponseCodes.R6_2;
          } else {
            responseCode = BudgetResponseCodes.R6_3;
          }
        }

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q6]: {
            ...currentCollection[BudgetQuestionCodes.Q6],
            code: questionCode,
            location: locationData,
            questionType: this.getQuestionType(questionCode),
            response: responseCode,
          },
        };
      },
      [BudgetQuestionCodes.Q7]: () => {
        const wantService = address && location;
        let responseCode: BudgetResponseCodes = BudgetResponseCodes.R7_4;
        let locationData;

        if (wantService) {
          locationData = {
            city: address?.city,
            country: address?.country,
            location: location?.id,
            province: address?.province,
          };

          if (address?.work) {
            responseCode = location?.work
              ? BudgetResponseCodes.R7_1
              : BudgetResponseCodes.R7_2;
          } else {
            responseCode = BudgetResponseCodes.R7_3;
          }
        }

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q7]: {
            ...currentCollection[BudgetQuestionCodes.Q7],
            code: questionCode,
            location: locationData,
            questionType: this.getQuestionType(questionCode),
            response: responseCode,
          },
        };
      },
      [BudgetQuestionCodes.Q8]: () => {
        const wantService = address && location;

        let responseCode: BudgetResponseCodes = BudgetResponseCodes.R8_4;
        let locationData;

        if (wantService) {
          locationData = {
            city: address?.city,
            country: address?.country,
            location: location?.id,
            province: address?.province,
          };
          if (address?.work) {
            responseCode = location?.work
              ? BudgetResponseCodes.R8_1
              : BudgetResponseCodes.R8_2;
          } else {
            responseCode = BudgetResponseCodes.R8_3;
          }
        }

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q8]: {
            ...currentCollection[BudgetQuestionCodes.Q8],
            code: questionCode,
            location: locationData,
            questionType: this.getQuestionType(questionCode),
            response: responseCode,
          },
        };
      },
      [BudgetQuestionCodes.Q9]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q9]: {
          ...currentCollection[BudgetQuestionCodes.Q9],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R9_1
            : BudgetResponseCodes.R9_2,
        },
      }),
      [BudgetQuestionCodes.Q10]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q10]: {
          ...currentCollection[BudgetQuestionCodes.Q10],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          questionType: BudgetQuestionTypes.COFFIN,
          response: articles.length > 0
            ? BudgetResponseCodes.R10_1
            : BudgetResponseCodes.R10_2,
        },
      }),
      [BudgetQuestionCodes.Q11]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q11]: {
          ...currentCollection[BudgetQuestionCodes.Q11],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R11_1
            : BudgetResponseCodes.R11_2,
        },
      }),
      [BudgetQuestionCodes.Q12]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q12]: {
          ...currentCollection[BudgetQuestionCodes.Q12],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R12_1
            : BudgetResponseCodes.R12_2,
        },
      }),
      [BudgetQuestionCodes.Q13]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q13]: {
          ...currentCollection[BudgetQuestionCodes.Q13],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R13_1
            : BudgetResponseCodes.R13_2,
        },
      }),
      [BudgetQuestionCodes.Q14]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q14]: {
          ...currentCollection[BudgetQuestionCodes.Q14],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R14_1
            : BudgetResponseCodes.R14_2,
        },
      }),
      [BudgetQuestionCodes.Q15]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q15]: {
          ...currentCollection[BudgetQuestionCodes.Q15],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R15_1
            : BudgetResponseCodes.R15_2,
        },
      }),
      [BudgetQuestionCodes.Q17]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q17]: {
          ...currentCollection[BudgetQuestionCodes.Q17],
          code: questionCode,
          location: address
            ? {
              ...address,
            }
            : undefined,
          questionType: this.getQuestionType(questionCode),
          response: address
            ? BudgetResponseCodes.R17_1
            : BudgetResponseCodes.R17_2,
        },
      }),
      [BudgetQuestionCodes.Q19]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q19]: {
          ...currentCollection[BudgetQuestionCodes.Q19],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R19_1
            : BudgetResponseCodes.R19_2,
        },
      }),
      [BudgetQuestionCodes.Q21]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q21]: {
          ...currentCollection[BudgetQuestionCodes.Q21],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R21_1
            : BudgetResponseCodes.R21_2,
        },
      }),
      [BudgetQuestionCodes.Q22]: () => {
        const responses: Record<string, BudgetResponseCodes> = {
          [CeremonyTypes.CATOLIC]: BudgetResponseCodes.R22_1,
          [CeremonyTypes.OTHER]: BudgetResponseCodes.R22_2,
          [CeremonyTypes.CIVIL]: BudgetResponseCodes.R22_3,
        };

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q22]: {
            ...currentCollection[BudgetQuestionCodes.Q22],
            code: questionCode,
            questionType: BudgetQuestionTypes.CEREMONY_TYPE,
            response: responses[service],
          },
        };
      },
      [BudgetQuestionCodes.Q23]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q23]: {
          ...currentCollection[BudgetQuestionCodes.Q23],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R23_1
            : BudgetResponseCodes.R23_2,
        },
      }),
      [BudgetQuestionCodes.Q24]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q24]: {
          ...currentCollection[BudgetQuestionCodes.Q24],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R24_1
            : BudgetResponseCodes.R24_2,
        },
      }),
      [BudgetQuestionCodes.Q25]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q25]: {
          ...currentCollection[BudgetQuestionCodes.Q25],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R25_1
            : BudgetResponseCodes.R25_2,
        },
      }),
      [BudgetQuestionCodes.Q27]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q27]: {
          ...currentCollection[BudgetQuestionCodes.Q27],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R27_1
            : BudgetResponseCodes.R27_2,
        },
      }),
      [BudgetQuestionCodes.Q28]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q28]: {
          ...currentCollection[BudgetQuestionCodes.Q28],
          code: questionCode,
          questionType: BudgetQuestionTypes.PACEMAKER,
          response: yesNo
            ? BudgetResponseCodes.R28_1
            : BudgetResponseCodes.R28_2,
        },
      }),
      [BudgetQuestionCodes.Q29]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q29]: {
          ...currentCollection[BudgetQuestionCodes.Q29],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R29_1
            : BudgetResponseCodes.R29_2,
        },
      }),
      [BudgetQuestionCodes.Q30]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q30]: {
          ...currentCollection[BudgetQuestionCodes.Q30],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R30_1
            : BudgetResponseCodes.R30_2,
        },
      }),
      [BudgetQuestionCodes.Q31]: () => {
        const {
          nicheType, nameChange, concessions, taxes, transfers,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = response as any;
        let q31response: BudgetResponseCodes = BudgetResponseCodes.R31_1;
        let art: IArticlesCollection = new ArticleCollection({});
        if (nicheType === NicheOwnershipTypes.NEW && !nameChange) {
          q31response = BudgetResponseCodes.R31_1;
          art = new ArticleCollection({
            [ArticleCollectionTypes.CONCESSION]: concessions,
            [ArticleCollectionTypes.TAX]: taxes,
          });
        } else if (nicheType === NicheOwnershipTypes.OLD && !nameChange) {
          q31response = BudgetResponseCodes.R31_2;
          art = new ArticleCollection({
            [ArticleCollectionTypes.TAX]: taxes,
          });
        } else if (nicheType === NicheOwnershipTypes.OLD && nameChange) {
          q31response = BudgetResponseCodes.R31_3;
          art = new ArticleCollection({
            [ArticleCollectionTypes.TAX]: taxes,
            [ArticleCollectionTypes.TRANSFER]: transfers,
          });
        }

        return {
          ...currentCollection,
          [BudgetQuestionCodes.Q31]: {
            ...currentCollection[BudgetQuestionCodes.Q31],
            articles: art,
            code: questionCode,
            response: q31response,
          },
        };
      },
      [BudgetQuestionCodes.Q36]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q36]: {
          ...currentCollection[BudgetQuestionCodes.Q36],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R36_1
            : BudgetResponseCodes.R36_2,
        },
      }),
      [BudgetQuestionCodes.Q38]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q38]: {
          ...currentCollection[BudgetQuestionCodes.Q38],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R38_1
            : BudgetResponseCodes.R38_2,
        },
      }),
      [BudgetQuestionCodes.Q39]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q39]: {
          ...currentCollection[BudgetQuestionCodes.Q39],
          code: questionCode,
          location: {
            city: address?.city,
            country: address?.country,
            location: location?.id,
            province: address?.province,
          },
          response: BudgetResponseCodes.R39_1,
        },
      }),
      [BudgetQuestionCodes.Q40]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q40]: {
          ...currentCollection[BudgetQuestionCodes.Q40],
          code: questionCode,
          response: yesNo
            ? BudgetResponseCodes.R40_1
            : BudgetResponseCodes.R40_2,
        },
      }),
      [BudgetQuestionCodes.Q42]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q42]: {
          ...currentCollection[BudgetQuestionCodes.Q42],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R42_1
            : BudgetResponseCodes.R42_2,
        },
      }),
      [BudgetQuestionCodes.Q43]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q43]: {
          ...currentCollection[BudgetQuestionCodes.Q43],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: BudgetResponseCodes.R43_1,
        },
      }),
      [BudgetQuestionCodes.Q44]: () => ({
        ...currentCollection,
        [BudgetQuestionCodes.Q44]: {
          ...currentCollection[BudgetQuestionCodes.Q44],
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: articles,
          }),
          code: questionCode,
          response: articles.length > 0
            ? BudgetResponseCodes.R44_1
            : BudgetResponseCodes.R44_2,
        },
      }),
    };

    return questions[questionCode]
      ? questions[questionCode]()
      : currentCollection;
  }

  public getQuestionType(question: BudgetQuestionCodes): BudgetQuestionTypes {
    const questionTypes: Record<string, BudgetQuestionTypes> = {
      [BudgetQuestionCodes.Q3]: BudgetQuestionTypes.DEATH,
      [BudgetQuestionCodes.Q4]: BudgetQuestionTypes.INSURANCE,
      [BudgetQuestionCodes.Q5]: BudgetQuestionTypes.VIGIL,
      [BudgetQuestionCodes.Q6]: BudgetQuestionTypes.CEREMONY,
      [BudgetQuestionCodes.Q7]: BudgetQuestionTypes.CREMATION,
      [BudgetQuestionCodes.Q8]: BudgetQuestionTypes.INTERMENT,
      [BudgetQuestionCodes.Q17]: BudgetQuestionTypes.OBITUARY,
    };

    return questionTypes[question as BudgetQuestionCodes];
  }

  public availableQuestions(
    questions: BudgetQuestionCollection,
    cabreCollection = false,
  ): Array<BudgetQuestionCodes> {
    const q1Response = questions[BudgetQuestionCodes.Q1]?.response;
    const q3Response = questions[BudgetQuestionCodes.Q3]?.response;
    const q5Response = questions[BudgetQuestionCodes.Q5]?.response;
    const q6Response = questions[BudgetQuestionCodes.Q6]?.response;
    const q7Response = questions[BudgetQuestionCodes.Q7]?.response;
    const q8Response = questions[BudgetQuestionCodes.Q8]?.response;
    const q22Response = questions[BudgetQuestionCodes.Q22]?.response;
    const q36Response = questions[BudgetQuestionCodes.Q36]?.response;

    const R1_1 = q1Response === BudgetResponseCodes.R1_1;
    const R1_2 = q1Response === BudgetResponseCodes.R1_2;
    const R1_3 = q1Response === BudgetResponseCodes.R1_3;
    const R1_4 = q1Response === BudgetResponseCodes.R1_4;
    const R1_5 = q1Response === BudgetResponseCodes.R1_5;
    const R3_1 = q3Response === BudgetResponseCodes.R3_1;
    const R3_2 = q3Response === BudgetResponseCodes.R3_2;
    const R5_1 = q5Response === BudgetResponseCodes.R5_1;
    const R5_2 = q5Response === BudgetResponseCodes.R5_2;
    const R5_3 = q5Response === BudgetResponseCodes.R5_3;
    const R6_1 = q6Response === BudgetResponseCodes.R6_1;
    const R6_2 = q6Response === BudgetResponseCodes.R6_2;
    const R7_1 = q7Response === BudgetResponseCodes.R7_1;
    const R7_2 = q7Response === BudgetResponseCodes.R7_2;
    const NOT_R7_4 = q7Response !== BudgetResponseCodes.R7_4;
    const R8_1 = q8Response === BudgetResponseCodes.R8_1;
    const R8_2 = q8Response === BudgetResponseCodes.R8_2;
    const R22_1 = q22Response === BudgetResponseCodes.R22_1;
    const R22_3 = q22Response === BudgetResponseCodes.R22_3;
    const R36_2 = q36Response === BudgetResponseCodes.R36_2;

    const availabitilyFilter = (question: BudgetQuestionCodes): boolean => {
      const questionAvailabilityRules: Record<string, boolean> = {
        [BudgetQuestionCodes.Q1]: true,
        [BudgetQuestionCodes.Q2]: R1_1,
        [BudgetQuestionCodes.Q3]: R1_1,
        [BudgetQuestionCodes.Q4]: true,
        [BudgetQuestionCodes.Q5]: R1_1,
        [BudgetQuestionCodes.Q6]: R1_1 || R1_2 || R1_3,
        [BudgetQuestionCodes.Q7]: R1_1 || R1_2 || R1_3 || R1_4,
        [BudgetQuestionCodes.Q8]: R1_1 || R1_2 || R1_3 || R1_4 || R1_5,
        [BudgetQuestionCodes.Q9]: R3_1 || (R3_2 && cabreCollection),
        [BudgetQuestionCodes.Q11]: R1_1,
        [BudgetQuestionCodes.Q10]: R3_1 || (R3_2 && cabreCollection) || R36_2,
        [BudgetQuestionCodes.Q12]: R1_1,
        [BudgetQuestionCodes.Q13]: R5_1 || R5_2 || R5_3 || R6_1 || R6_2,
        [BudgetQuestionCodes.Q14]: R1_1,
        [BudgetQuestionCodes.Q15]: R5_1 || R5_2 || R5_3 || R6_1 || R6_2,
        [BudgetQuestionCodes.Q17]: R5_1 || R5_2 || R5_3 || R6_1 || R6_2,
        [BudgetQuestionCodes.Q19]: R5_1 || R5_2 || R5_3 || R6_1 || R6_2,
        [BudgetQuestionCodes.Q21]: R5_1 || R5_2 || R5_3 || R6_1 || R6_2,
        [BudgetQuestionCodes.Q22]: R6_1 || R6_2,
        [BudgetQuestionCodes.Q23]: R22_1,
        [BudgetQuestionCodes.Q24]: R5_1 || R5_2 || R5_3 || R6_1 || R6_2,
        [BudgetQuestionCodes.Q25]: R6_1,
        [BudgetQuestionCodes.Q27]: R7_1 || R7_2,
        [BudgetQuestionCodes.Q28]: (R3_1 || (R3_2 && cabreCollection)) && NOT_R7_4,
        [BudgetQuestionCodes.Q29]: R7_1 || R7_2,
        [BudgetQuestionCodes.Q30]: R7_1 || R7_2,
        [BudgetQuestionCodes.Q31]: R8_1 || R8_2,
        [BudgetQuestionCodes.Q36]: R3_2 && !cabreCollection,
        [BudgetQuestionCodes.Q38]: R22_3,
        [BudgetQuestionCodes.Q39]: R1_4,
        [BudgetQuestionCodes.Q40]: R5_1,
        [BudgetQuestionCodes.Q42]: R8_1 || R8_2,
        [BudgetQuestionCodes.Q43]: R1_1 || R1_5,
        [BudgetQuestionCodes.Q44]: true,
        [BudgetQuestionCodes.SUMMARY]: true,
      };

      return questionAvailabilityRules[question];
    };

    return this.questions.filter(availabitilyFilter);
  }

  static mapQuestionCollectionToArray(
    questions: BudgetQuestionCollection,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Array<any> {
    return Object.values(questions)
      .filter((question: IBudgetQuestion | null) => !isNull(question));
  }

  static mapQuestionsArrayToCollection(list: Array<IBudgetQuestion>): BudgetQuestionCollection {
    const emptyQuestionCollection: BudgetQuestionCollection = {
      [BudgetQuestionCodes.Q1]: null,
      [BudgetQuestionCodes.Q2]: null,
      [BudgetQuestionCodes.Q3]: null,
      [BudgetQuestionCodes.Q4]: null,
      [BudgetQuestionCodes.Q5]: null,
      [BudgetQuestionCodes.Q6]: null,
      [BudgetQuestionCodes.Q7]: null,
      [BudgetQuestionCodes.Q8]: null,
      [BudgetQuestionCodes.Q9]: null,
      [BudgetQuestionCodes.Q10]: null,
      [BudgetQuestionCodes.Q11]: null,
      [BudgetQuestionCodes.Q12]: null,
      [BudgetQuestionCodes.Q13]: null,
      [BudgetQuestionCodes.Q14]: null,
      [BudgetQuestionCodes.Q15]: null,
      [BudgetQuestionCodes.Q17]: null,
      [BudgetQuestionCodes.Q19]: null,
      [BudgetQuestionCodes.Q21]: null,
      [BudgetQuestionCodes.Q22]: null,
      [BudgetQuestionCodes.Q23]: null,
      [BudgetQuestionCodes.Q24]: null,
      [BudgetQuestionCodes.Q25]: null,
      [BudgetQuestionCodes.Q27]: null,
      [BudgetQuestionCodes.Q28]: null,
      [BudgetQuestionCodes.Q29]: null,
      [BudgetQuestionCodes.Q30]: null,
      [BudgetQuestionCodes.Q31]: null,
      [BudgetQuestionCodes.Q38]: null,
      [BudgetQuestionCodes.Q39]: null,
      [BudgetQuestionCodes.Q36]: null,
      [BudgetQuestionCodes.Q40]: null,
      [BudgetQuestionCodes.Q42]: null,
      [BudgetQuestionCodes.Q43]: null,
      [BudgetQuestionCodes.Q44]: null,
      [BudgetQuestionCodes.SUMMARY]: null,
    };

    return Object.keys(BudgetQuestionCodes).reduce((prev, current) => {
      const question = list.find((value) => value.code === current);
      const location = question?.location ? new BudgetLocation(question.location) : null;

      const currentQuestion = question
        ? new BudgetQuestion({
          ...question,
          articles: new ArticleCollection({
            [ArticleCollectionTypes.ARTICLE]: question.articles?.ARTICLE,
            [ArticleCollectionTypes.CONCESSION]: question.articles?.CONCESSION,
            [ArticleCollectionTypes.TAX]: question.articles?.TAX,
            [ArticleCollectionTypes.TRANSFER]: question.articles?.TRANSFER,
          }),
          location,
        })
        : null;

      return {
        ...prev,
        [current]: currentQuestion,
      };
    }, emptyQuestionCollection);
  }
}
