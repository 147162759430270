import React, { FC, useEffect, useState } from 'react';

import { normalizeEntity } from 'utils/normalizer';

import Select, { SelectProps } from 'components/base/Select';
import { OptionsType } from 'components/base/SelectWithInput';

import { i18n } from 'i18n';

import './index.scss';

const languages: OptionsType[] = [
  { label: i18n.t('common.language.catalan'), value: '1' },
  { label: i18n.t('common.language.basque'), value: '2' },
  { label: i18n.t('common.language.english'), value: '3' },
  { label: i18n.t('common.language.french'), value: '4' },
  { label: i18n.t('common.language.german'), value: '5' },
  { label: i18n.t('common.language.italian'), value: '6' },
  { label: i18n.t('common.language.spanish'), value: '8' },
];

const normalizedLanguages = normalizeEntity<OptionsType>(languages, 'value');

type LanguageSelectorProps = Pick<
  SelectProps<OptionsType>,
  Exclude<
    keyof SelectProps<OptionsType>,
    'options' | 'getLabel' | 'getValue' | 'value'
  >
> & {
  value?: string;
};

const LanguageSelector: FC<LanguageSelectorProps> = ({
  className,
  clearable,
  disabled,
  error,
  limit,
  name,
  onBlur,
  onChange,
  openUp,
  placeholder,
  searchable,
  value,
}) => {
  const [selectedValue, setSelectedValue] = useState<OptionsType>();

  useEffect(() => {
    setSelectedValue(value ? normalizedLanguages[value] : undefined);
  }, [value]);

  return (
    <Select<OptionsType>
      className={className}
      clearable={clearable}
      disabled={disabled}
      error={error}
      getLabel={(elem) => elem.label}
      getValue={(elem) => elem.value}
      limit={limit}
      name={name}
      openUp={openUp}
      options={languages}
      placeholder={placeholder}
      searchable={searchable}
      value={selectedValue}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default LanguageSelector;
