const validationRules = {
  array: {
    haveAtLeastOne: 'Ha de tenir al menys un permís',
    max: 'Ha de tenir com a molt {{max}} elements',
    min: 'Ha de tenir al menys {{min}} elements',
  },
  date: {
    max: 'La data màxima ha de ser {{max}}',
    min: 'La data mínima ha de ser {{min}}',
    wrongTime: 'Heu d\'usar el format d\'hora HH:mm',
  },
  number: {
    integer: 'Ha de ser un nombre enter',
    lessThan: 'Ha de ser menor que {{less}}',
    max: 'El valor màxim és {{max}}',
    min: 'El valor mínim és {{min}}',
    moreThan: 'Ha de ser major que {{more}}',
    negative: 'Ha de ser un nombre negatiu',
    positive: 'Ha de ser un nombre positiu',
  },
  required: 'Obligatori',
  requiredDocument: 'Document requerit',
  string: {
    alphanumeric: 'Ha de ser alfanumèric',
    cif: 'El format del CIF no és correcte',
    email: 'Ha de ser un correu electrònic vàlid',
    identifier: 'El format del DNI no és correcte',
    ip: 'El format de l\'adreça no és correcte',
    length: 'La longitud ha de ser {{length}}',
    max: 'La longitud màxima ha de ser {{max}} caràcters',
    min: 'La longitud mínima ha de ser {{min}} caràcters',
    nie: 'El format del NIE no és correcte',
    passport: 'El format del passaport no és correcte',
  },
  typeError: 'Error de format',

};

export default validationRules;
