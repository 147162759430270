const budget = {
  actions: {
    accept: 'Aceptar presupuesto',
    acceptQuestion: '¿Estás seguro de que quieres aceptar este presupuesto?',
    acceptSuccess: 'Presupuesto aceptado',
    allArticlesClient: 'Asignar cliente para todos los artículos',
    alreadySigned: 'La familia ha firmado manualmente',
    assignRecord: 'Asignar expediente',
    assignSuccess: 'Presupuesto asignado correctamente al expdente',
    backward: 'Retroceder a estado anterior',
    backwardConfirm: {
      CANCELLED: 'Se cambiará el estado del presupuesto de CANCELADO a PENDIENTE, ¿está seguro?',
      INVOICED: 'Se cambiará el estado del presupuesto de FACTURADO a FIRMADO, ¿está seguro?',
      PRE_ACEPTED: 'Se cambiará el estado del presupuesto de PRE ACEPTADO a PENDIENTE, ¿está seguro?',
      SIGNED: 'Se cambiará el estado del presupuesto de FIRMADO a PRE ACEPTADO, ¿está seguro?',
    },
    backwardSuccess: 'Presupuesto actualizado',
    backwardSuccessInvoiced: 'Revise aplicación Ekon para evitar duplicidad de servicios cuando se vuelva a volcar la información',
    cancel: 'Cancelar',
    cancelSuccess: 'Presupuesto cancelado correctamente',
    confirmAssignRecord: '¿Estás seguro de que quieres asignar este presupuesto al expediente {{id}}, {{name}} {{firstSurname}} {{secondSurname}}?',
    discardAutomatic: 'Descartar artículo automático',
    duplicate: 'Duplicar presupuesto',
    duplicateSuccess: 'Presupuesto duplicado correctamente',
    editQuestion: 'Modificar respuesta',
    invoice: 'Enviar líneas a Ekon',
    invoiceSuccess: 'Presupuesto facturado',
    restoreDiscarded: 'Restaurar descartado',
    ruleSaveSuccess: 'Configuración actualizada correctamente',
    send: 'Enviar presupuesto',
    sendSuccess: 'Líneas enviadas a Ekon correctamente',
    sign: 'Firmar presupuesto',
    signConfirm: 'Confirmar firma',
    signSuccess: 'Firmado correctamente',
  },
  article: 'Artículo',
  articleImage: 'Imagen del artículo',
  articleSubType: 'Subtipo de artículo',
  articleSubTypes: {
    ARTICLE: 'Artículo',
    CONCESION: 'Concesión',
    TAX: 'Impuesto',
    TRANSFER: 'Transferencia',
  },
  articleType: 'Tipo de artículo',
  articleTypes: {
    AUTOMATIC: 'Automático',
    CATALOG: 'Catálogo',
  },
  block: 'Bloque',
  budgetNumber: 'Nº de presupuesto',
  ceremonyTypes: {
    CATOLIC: 'Católica',
    CIVIL: 'Civil',
    OTHER: 'Otras',
  },
  clientServicesAmount: 'Serveis client',
  coffinSize: 'Tamaño Fèretre',
  config: {
    article: 'Artículo',
    new: 'Nueva configuración',
    question: 'Pregunta',
    title: 'Administrador Presupuesto',
  },
  configurationAdd: 'Agregar la configuración',
  discarded: 'Artículos descartados',
  errores: {
    articleRequired: 'Debe seleccionar un artículo',
    block: 'El bloque es requerido',
    coffinSizeRequired: 'El tamaño es requerido para los artículos de féretro',
    questionRequired: 'La pregunta asociada es requerida en los artículos de catálogo',
    subTypeRequired: 'El subtipo de artículo es requerido',
    typeRequired: 'El tipo de artículo es requerido',
  },
  excludedArticles: 'Artículos excluidos',
  excludedInsurance: 'Seguros excluidos',
  excludedInsurances: 'Seguros excluidos',
  excludedLocations: 'Ubicaciones excluidas',
  excludedResponse: 'Respuesta opcional',
  hasArticleRule: 'Conf. por artículo',
  hasClientRule: 'Conf. por cliente',
  hasLocationRule: 'Conf. por localización',
  hasResponseRule: 'Conf. por respuesta',
  hasRule: 'Tiene configuración',
  includedArticles: 'Artículos incluidos',
  includedInsurances: 'Seguros incluidos',
  includedLocations: 'Ubicaciones incluidas',
  insurance: 'Assegurança',
  locations: 'Ubicaciones',
  locationType: 'Tipo de ubicación',
  locationTypes: {
    DEATH: 'Lugar de defunción',
    INSURANCE: 'Seguro',
    OBITUARY: 'Esquela',
  },
  mandatoryResponses: 'Respostes obligatòries',
  nicheProperties: {
    NEW: 'nueva',
    OLD: 'antigua',
  },
  noInsurance: 'No tiene seguro',
  optionalResponses: 'Respostes opcionals',
  pressObituaries: 'Esquelas prensa',
  processing: 'Tramitación',
  processor: 'Tramitador',
  question: 'Pregunta',
  rejectService: 'No quiere el servicio',
  relatedArticles: 'Articles relacionats',
  response: 'Respuesta',
  responses: {
    1.1: 'Servicio de defunción',
    1.2: 'Servicio de amputación',
    1.3: 'Servicio de aborto',
    1.4: 'Servicio de despojos',
    1.5: 'Servicio de inhumación de cenizas',
    2.1: 'Ya se ha producido el fallecimiento',
    2.2: 'Todavía no se ha producido el fallecimiento',
    3.1: 'La población de defunción pertenece a Cabré',
    3.2: 'La población de defunción no pertenece a Cabré',
    4.1: 'Cliente principal particular',
    4.2: 'Cliente principal de compañía',
    5.1: 'Vela a instalaciones de Cabré',
    5.2: 'Vela a instalaciones fuera de Cabré y población de Cabré',
    5.3: 'Vela a una población fuera de Cabré',
    5.4: 'No quiere Vela',
    6.1: 'Ceremonia en instalaciones de Cabré',
    6.2: 'Ceremonia en instalaciones fuera de Cabré y población de Cabré',
    6.3: 'Ceremonia a una población fuera de Cabré',
    6.4: 'No quiere Ceremonia',
    7.1: 'Incineración en instalaciones de Cabré',
    7.2: 'Incineración en instalaciones fuera de Cabré y población de Cabré',
    7.3: 'Incineración a una población fuera de Cabré',
    7.4: 'No quiere Incineración',
    8.1: 'Inhumación en instalaciones de Cabré',
    8.2: 'Inhumación en instalaciones fuera de Cabré y población de Cabré',
    8.3: 'Inhumación a una población fuera de Cabré',
    8.4: 'No quiere Inhumación',
    9.1: 'Se requiere tantograxia',
    9.2: 'NO se requiere tanatoplaxia',
    10.1: 'Se quiere féretro',
    10.2: 'No se quiere féretro',
    11.1: 'Se quiere servicio jurídico',
    11.2: 'NO se quiere servicio jurídico',
    12.1: 'Se requiere servicio de depósito',
    12.2: 'NO se requiere servicio de depósito',
    13.1: 'Se quiere servicio de catering',
    13.2: 'NO se quiere servicio de catering',
    14.1: 'Se quiere servicio de flores',
    14.2: 'NO se quiere servicio de flores',
    15.1: 'Se quieren recordatorios',
    15.2: 'NO se quieren recordatorios',
    17.1: 'se quieren las esquelas de población',
    17.2: 'NO se quieren las esquelas de población',
    19.1: 'Se quiere esquelas prensa',
    19.2: 'NO se quiere esquelas prensa',
    21.1: 'Se quiere servicio de taxis',
    21.2: 'NO se quiere servicio de taxis',
    22.1: 'Ceremonia católica',
    22.2: 'Ceremonia Civil',
    22.3: 'Otros tipos de ceremonia',
    23.1: 'El cura es proveedor propio',
    23.2: 'El cura es proveedor de la familia',
    24.1: 'Se quiere servicio musical',
    24.2: 'NO se quiere servicio musical',
    25.1: 'Se quiere servicio de Streaming',
    25.2: 'NO se quiere servicio de Streaming',
    27.1: 'Se quiere urna',
    27.2: 'NO se quiere urna',
    28.1: 'El difunto tenía marcapasos',
    28.2: 'El difunto NO tenía marcapasos',
    29.1: 'Se quieren reliquiarios',
    29.2: 'NO se quieren reliquiarios',
    30.1: 'Se quieren colgajos',
    30.2: 'NO se quieren colgajos',
    31.1: 'Nueva',
    31.2: 'Antigua, menos de 5 años, sin cambio nombre',
    31.3: 'Antigua, más de 5 años, sin cambio de nombre',
    31.4: 'Antigua, más de 5 años, con cambio de nombre',
    31.5: 'Antigua, menos de 5 años, con cambio de nombre',
    36.1: 'SÍ, el difunto llega encajado',
    36.2: 'NO, es un protocolo litera',
    38.1: 'Proveedor Propio',
    38.2: 'Proveedor de la familia',
    39.1: 'En caso de traslado, de qué cementerio proviene',
    40.1: 'NO se quiere recoger de imágenes vivo recuerdo',
    40.2: 'Se quiere recoger de imágenes vivo recuerdo',
    42.1: 'Se utiliza sepulturero',
    42.2: 'No se utiliza sepulturero',
    43.1: 'Se selecciona despacho y tramitación',
    44.1: 'Se quieren Artículos adicionales',
    44.2: 'NO se quieren Artículos adicionales',
  },
  serviceTypes: {
    AMPUTACIO: 'Amputación',
    AVORTAMENT: 'Aborto',
    DEFUNCIO: 'Defunción',
    DESPULLES: 'Restos',
    INHUMACIO_CENDRES: 'Inhumación cenizas',
  },
  showNotActiveArticles: 'Mostrar sólo artículos sin configuración',
  sorting: 'Orden',
  states: {
    CANCELLED: 'CANCELADO',
    FIRMO: 'FIRMADO',
    INVOICED: 'FACTURADO',
    PENDING: 'PENDIENTE',
    PRE_ACEPTED: 'PRE ACEPTADO',
  },
  title: 'Seguimiento presupuestos',
  totalAmount: 'Import total',
  vatAmount: 'IVA',
  wizard: {
    backButton: 'Anterior',
    cemeteryTaxesQuestion: '¿Qué tipo de tasa es requerida?',
    changeAffectedQuestions: 'Estas respuestas se perderán al modificar esta pregunta',
    city: {
      ceremony: 'Població de cerimònia',
      cremation: 'Població de cremació',
      death: 'Població de defunció',
      interment: 'Població d\'enterrament',
      vigil: 'Població de vetlla',
    },
    collectedYes: 'En la recogida se ha observado que el estado del difunto es: "{{status}}"',
    commonDismiss: 'No quiere el servicio',
    concessionQuestion: '¿Qué tipo de concesión se desea?',
    country: {
      ceremony: 'País de cerimònia',
      cremation: 'País de cremació',
      death: 'País de defunció',
      interment: 'País d\'enterrament',
      vigil: 'País de vetlla',
    },
    date: 'Fecha',
    dismissItemCaption: {
      Q10: 'No se quiere féretro',
      Q11: 'No se quiere contratar servicio jurídico',
      Q13: 'No se quiere catering',
      Q14: 'No se quiere flores',
      Q15: 'No se quiere recordatorios',
      Q21: 'NO, no se quiere servicio de taxi',
      Q24: 'No se quiere servicio musical',
      Q27: 'No se quiere urna',
      Q29: 'No se quiere relicarios',
      Q30: 'No se quiere joyas o colgantes',
      Q31: 'No quiere el servicio',
      Q42: 'No quiere el servicio',
      Q44: 'No quiere el servicio',
    },
    gotoTaxes: 'Seleccionar tasas',
    minus: '-',
    nameChange: '¿Se quiere un cambio de nombre?',
    new: 'Nuevo presupuesto',
    no: {
      Q2: 'No se ha producido el fallecimiento',
      Q4: 'NO dispone de seguro',
      Q5: 'NO, no se quiere vela',
      Q6: 'NO, no se quiere ceremonia',
      Q7: 'NO, no se quiere incineración',
      Q8: 'NO, no se quiere inhumación',
      Q9: 'NO, no se requiere tanatoplastia',
      Q12: 'No, no se requiere servicio de depósito',
      Q17: 'NO, no se quieren esquelas de población',
      Q19: 'NO, no se quieren esquelas de prensa',
      Q21: 'NO, no se quiere servicio de taxi',
      Q23: 'Proveedor de la familia',
      Q25: 'No, NO se quiere servicio de Streaming',
      Q28: 'NO, no tenía marcapasos',
      Q36: 'NO, es un protocolo litera',
      Q38: 'Proveedor de la familia',
      Q40: 'No, NO se quiere imagen vivo recuerdo',
    },
    olderNiche: 'Antigua de más de 5 años',
    otherCeremonyTypes: 'Otros tipos de ceremonia',
    plus: '+',
    province: {
      ceremony: 'Provincia de cerimònia',
      cremation: 'Provincia de cremació',
      death: 'Provincia de defunció',
      interment: 'Provincia d\'enterrament',
      vigil: 'Provincia de vetlla',
    },
    Q1: '¿Cuál es el tipo de servicio?',
    Q2: '¿Ya se ha producido el fallecimiento?',
    Q3: 'Población y lugar de fallecimiento',
    Q4: 'En caso de que tenga seguro, ¿cuál se dispone?',
    Q5: '¿En qué población y en qué ubicación se quiere el velatorio?',
    Q6: '¿En qué población y en qué ubicación se quiere ceremonia?',
    Q7: '¿En qué población y en qué ubicación se quiere la incineración?',
    Q8: 'A quina població i a quina ubicació es vol l\'incineració?',
    Q9: 'En caso de que el asesor tenga información del estado del difunto. ¿Se requiere tanatoplastia?',
    Q10: '¿Qué féretro se quiere?',
    Q11: '¿Se quiere contratar el servicio jurídico?',
    Q12: '¿Se requiere servicio de depósito?',
    Q13: '¿Se quiere servicio de catering?',
    Q14: '¿Se quiere servicio de flores?',
    Q15: '¿Se quiere recordatorios?',
    Q16: '¿Se quiere esquelas en la población?',
    Q17: '¿A qué población se quieren las esquelas de población?',
    Q19: '¿Qué tipo de esquelas prensa se quiere?',
    Q21: '¿Se quiere servicio de taxis?',
    Q22: '¿Qué tipo de ceremonia se quiere?',
    Q23: '¿Proveedor del cura?',
    Q24: '¿Se quiere servicio musical?',
    Q25: '¿Se quiere servicio de Streaming?',
    Q27: '¿Qué urna se quiere?',
    Q28: '¿El difunto tenía marcapasos?',
    Q29: '¿Se quieren relicarios?',
    Q30: '¿Se quieren colgantes?',
    Q31: '¿Tipo Sepultura?',
    Q36: 'En caso de no recoger nosotros, ¿el difunto llega encajado?',
    Q37: '¿Se quiere un cambio de nombre?',
    Q38: 'Proveedor Maestro Ceremonias?',
    Q39: 'En caso de traslado, ¿de qué cementerio proviene?',
    Q40: '¿Se quiere recopilación de imágenes vivo recuerdo?',
    Q42: '¿Qué sepultureros quieren usar?',
    Q43: 'Despacho y tramitación',
    Q44: '¿Quieres añadir Artículos adicionales?',
    responseButton: 'Siguiente',
    selectConcession: 'Seleccionar concesión',
    selectTax: 'Seleccionar tasa',
    selectTransfer: 'Seleccionar traspaso',
    serviceType: 'Tipo de servicio',
    short: {
      Q10: 'Féretro',
      Q13: 'Catering',
      Q14: 'Flores',
      Q15: 'Recordatorios',
      Q17: 'Esquelas Pobl.',
      Q19: 'Esquelas Prensa',
      Q21: 'Taxis',
      Q24: 'Music',
      Q27: 'Urna',
      Q29: 'Relicarios',
      Q30: 'Colgantes',
      Q44: 'Artículos adicionales',
    },
    summary: {
      amountEdit: 'Haz click para editar la cantidad',
      article: 'Artículo',
      clientTypes: {
        COMPANY: 'Compañia',
        INDIVIDUAL: 'Particular',
      },
      confirmAmount: 'Confirmar cambio de cantidad',
      confirmPrice: 'Confirmar cambio de precio',
      description: 'Descripción',
      price: 'Precio',
      priceEdit: 'Haz click para editar el precio',
      quantity: 'Cantidad',
      title: 'Presupuesto',
      unitPrice: 'Precio por unidad',
    },
    totalServiceAmount: 'El total del servicio es: {{amount}}',
    transferQuestion: '¿Se quiere un cambio de nombre?',
    vigilDate: 'Data de inicio del velatorio',
    yes: {
      Q2: 'SÍ, se ha producido el fallecimiento',
      Q4: 'Dispone de seguro',
      Q5: 'SÍ, se quiere vela',
      Q6: 'SÍ, se quiere ceremonia',
      Q7: 'SÍ, se quiere incineración',
      Q8: 'SÍ, se quiere inhumación',
      Q9: 'SÍ, se requiere tanatoplastia',
      Q12: 'SÍ, requiere servicio de depósito',
      Q17: 'SÍ, se quieren esquelas de población',
      Q19: 'SÍ, se quieren esquelas de prensa',
      Q21: 'SÍ, se quiere servicio de taxi',
      Q23: 'Proveedor propio',
      Q25: 'Sí, se quiere servicio de Streaming',
      Q28: 'SÍ, tenía maracapasos',
      Q36: 'SÍ, el difunto llega encajado',
      Q38: 'Proveedor Propio',
      Q40: 'Sí, se quiere imagen vivo recuerdo',
    },
  },
};
export default budget;
