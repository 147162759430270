const error = {
  500: 'No se puede conectar con el servidor en estos momentos',
  concurrency: 'Los datos ya han sido modificados por un altre usuari, es necessari recarregar la pàgina.',
  deceased: {
    exist: 'Ya existe un expediente para un fallecimiento con este documento identificativo',
  },
  emailFormat: 'Debe ser un email válido',
  fallbackError: {
    dashboard: 'escritorio',
    error: 'Error',
    go: 'Ir a',
    stacktrace: 'Más información',
    title: 'ERROR',
  },
  forbidden: 'Revise permisos del usuario',
  forgot: 'Se ha producido un error. Por favor, asegurese que el nombre de usuario es correcto y vuelva a intentarlo',
  ia: {
    backSideRequired: 'No se ha detectado ninguna cara trasera',
    frontSideRequired: 'No se ha detectado ninguna cara delantera',
    maxFrontOrBackSidesExceded: 'No debe haber más de un front o un back',
    pdfrequired: 'El archivo debe ser un PDF',
  },
  incineration: {
    authorization: 'No se puede proceder con la incineración debido a que NO SE DISPONE DE TODA LA DOCUMENTACIÓN PARA INCINERAR',
  },
  invalidToken: 'El token es inválido, por favor, solicite de nuevo el cambio de contraseña.',
  login: {
    generic: 'Algo ha ido mal',
    request: 'Error de conexión',
    userPass: 'Usuario o contraseña incorrecto',
  },
  minLength: 'La longitud mínima es',
  passwordMatch: 'Las contraseñas deben coincidir',
  required: 'Requerido',
  unprocessableEntity: 'NNo s\'ha pogut processar la sol·licitud',
};
export default error;
