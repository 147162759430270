/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, {
  FC, ReactElement, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateAndHour } from 'utils/dateManager';
import { isNumber } from 'utils/helpers';
import { checkHaveAtLeastOnePermission } from 'utils/permissions';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import FilteredPage from 'components/base/FilteredPage';
import SideFilterField from 'components/base/SideFilters/SideFilterField';
import { TableColumn } from 'components/base/Table/types';

import { DeleteRequestModal } from 'components/pages/articles/deleteRequestModal';
import { EditDraftModal } from 'components/pages/articles/editDraftModal';
import { ViewFlowerModal } from 'components/pages/articles/viewFlowerModal';

import {
  ArticleTrackingFilter,
  ArticleTrackingFilterChannel,
  ArticleTrackingFilterStatus,
  ArticleTrackingDto,
  SimpleUserRequestPermissionNames,
} from 'config/apiFunus/generated/data-contracts';
import { IFilter } from 'config/apiFunus/types';
import { useArticles } from 'hooks/useArticles';
import { useFeatures } from 'hooks/useFeatures/useFeatures';
import { useProvidedAuth } from 'hooks/useProvidedAuth';
import { useVigilLocation } from 'hooks/useVigilLocation';
import { priceFormat } from 'modules/budget/components/budgetSummary/tools';

import { AcceptRequestModal } from '../../../acceptRequestModal';
import { AddArticleDisagreementModal } from '../../../addArticleDisagreementModal';
import { CancelRequestModal } from '../../../cancelRequestModal';
import { PrintModal } from '../../../printModal';

interface FlowersTableProps {
  erpId?: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArticlesTableColumn = TableColumn<any>

export const FlowersTable: FC<FlowersTableProps> = ({ erpId }) => {
  const { t } = useTranslation();
  const { fetchArticles } = useArticles();
  const [showCancelRequest, setShowCancelRequest] = useState<boolean>(false);
  const [showDeleteRequest, setShowDeleteRequest] = useState<boolean>(false);
  const [showAcceptRequest, setShowAcceptRequest] = useState<boolean>(false);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [showEditDraftModal, setShowEditDraftModal] = useState<boolean>(false);
  const [showAddDisaggeementModal, setshowAddDisaggeementModal] = useState<boolean>(false);
  const [showViewFlowerPhotoModal, setshowViewFlowerPhotoModal] = useState<boolean>(false);
  const [articleId, setArticleId] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [successCallback, setsuccessCallback] = useState<any>();
  const {
    canAcceptFlowersRequest,
    canAddArticleDisagreement,
    canCancelRequest,
    canDeleteRequest,
    canViewPrintable,
    canEditArticleRequestDraft,
    canShowFlowersPhoto,
  } = useFeatures();
  const { user } = useProvidedAuth();

  const loadArticles = useCallback(
    (
      filter: IFilter<ArticleTrackingFilter>,
    ) => fetchArticles(filter.filter, filter.page, filter.sort, !erpId)
      .catch(() => false),
    [erpId, fetchArticles],
  );

  const isSupplier = useMemo(
    () => checkHaveAtLeastOnePermission(
      [
        SimpleUserRequestPermissionNames.ARTICLE_SUPPLIER,
      ],
      user?.role.permissions,
    ),
    [user?.role.permissions],
  );

  const fields = useCallback(
    (
      formikProps: FormikProps<ArticleTrackingFilter>,
    ): ReactElement[] => [
      <SideFilterField
        key="erpId"
        filterType="input"
        formikProps={formikProps}
        name="erpId"
        placeholder={`${t('record.number')}`}
      />,
      <SideFilterField
        key="deceasedName"
        filterType="input"
        formikProps={formikProps}
        name="deceasedName"
        placeholder={`${t('common.name')}`}
      />,
      <SideFilterField
        key="deceasedFirstSurname"
        filterType="input"
        formikProps={formikProps}
        name="deceasedFirstSurname"
        placeholder={`${t('common.firstSurname')}`}
      />,
      <SideFilterField
        key="deceadedSecondSurname"
        filterType="input"
        formikProps={formikProps}
        name="deceadedSecondSurname"
        placeholder={`${t('common.secondSurname')}`}
      />,
      <SideFilterField<ArticleTrackingFilter, ArticleTrackingFilterStatus>
        key="status"
        filterType="select"
        formikProps={formikProps}
        getLabel={(value) => t(`article.flowers.states.${value}`)}
        getValue={(value) => value}
        name="status"
        options={Object.values(ArticleTrackingFilterStatus)}
        placeholder={`${t('common.status')}`}
        clearable
      />,
      <SideFilterField<ArticleTrackingFilter, ArticleTrackingFilterChannel>
        key="channel"
        filterType="select"
        formikProps={formikProps}
        getLabel={(value) => t(`article.flowers.channels.${value}`)}
        getValue={(value) => value}
        name="channel"
        options={Object.values(ArticleTrackingFilterChannel)}
        placeholder={`${t('common.channel')}`}
        clearable
      />,
    ],
    [t],
  );

  const columns = useMemo(
    (): ArticlesTableColumn[] => {
      const erpIdColumn = {
        accessor: 'erpId',
        Header: `${t('common.erpId')}`,
        sortable: false,
      };
      const deceasedNameColumn: ArticlesTableColumn = {
        accessor: 'deceasedName',
        Cell: ({
          row: {
            original: {
              deceasedName, deceasedFirstSurname, deceadedSecondSurname: deceasedSecondSurname,
            },
          },
        }) => classNames(deceasedName,
          deceasedFirstSurname,
          deceasedSecondSurname) || '',
        Header: `${t('common.name')}`,
        sortable: false,
      };
      const vigilPlaceColumn: ArticlesTableColumn = {
        accessor: 'vigilPlace',
        Cell: ({
          row: {
            original: {
              city, location,
            },
          },
        }) => {
          const { getVigilLocation } = useVigilLocation(city);

          return (<React.Fragment>{getVigilLocation(location)?.description}</React.Fragment>);
        },
        Header: `${t('common.location')}`,
        sortable: false,
      };
      const descriptionColumn = {
        accessor: 'description',
        Header: `${t('common.description')}`,
        sortable: false,
      };
      const channelColumn: ArticlesTableColumn = {
        accessor: 'channel',
        Cell: ({ cell: { value } }) => t(`article.flowers.channels.${value}`),
        Header: `${t('common.channel')}`,
        sortable: false,
      };
      const statusColumn: ArticlesTableColumn = {
        accessor: 'status',
        Cell: ({ cell: { value } }) => t(`article.flowers.states.${value}`),
        Header: `${t('common.status')}`,
        sortable: false,
      };
      const dedicatoryColumn = {
        accessor: 'dedicatory',
        Header: `${t('article.flowers.dedicatory')}`,
        sortable: false,
      };
      const datetimeColumn: ArticlesTableColumn = {
        accessor: 'createdDateTime',
        Cell: ({
          row: {
            original: {
              createdDateTime,
            },
          },
        }) => (<React.Fragment>{createdDateTime ? formatDateAndHour(createdDateTime) : '-'}</React.Fragment>),
        Header: `${t('record.creationDate')}`,
        sortable: false,
      };
      const priceColumn: ArticlesTableColumn = {
        accessor: 'price',
        Cell: ({
          row: {
            original: {
              price,
            },
          },
        }) => (<React.Fragment>{isNumber(price) ? priceFormat(price) : '-'}</React.Fragment>),
        Header: `${t('common.price')}`,
        sortable: false,
      };

      if (erpId) {
        return [
          channelColumn,
          descriptionColumn,
          dedicatoryColumn,
          datetimeColumn,
          priceColumn,
          statusColumn,
        ];
      }

      if (isSupplier) {
        return [
          erpIdColumn,
          deceasedNameColumn,
          vigilPlaceColumn,
          dedicatoryColumn,
          statusColumn,
          datetimeColumn,
          priceColumn,
        ];
      }

      return [
        erpIdColumn,
        deceasedNameColumn,
        vigilPlaceColumn,
        descriptionColumn,
        dedicatoryColumn,
        channelColumn,
        statusColumn,
        datetimeColumn,
        priceColumn,
      ];
    },
    [erpId, isSupplier, t],
  );

  const onModalDraftClose = useCallback(
    () => {
      setShowEditDraftModal(false);
      setArticleId(undefined);
    },
    [],
  );

  const editDraftModalOnSuccess = useCallback(
    () => {
      successCallback?.();
      setShowEditDraftModal(false);
      setArticleId(undefined);
    },
    [successCallback],
  );

  return (
    <React.Fragment>
      <FilteredPage
        actions={(articleTrackingItem: ArticleTrackingDto) => ({
          canEditDraft: canEditArticleRequestDraft(articleTrackingItem) ? {
            icon: <CustomIcon icon={OtherTypes.ARTICLE_DRAFT_EDIT} />,
            onClick: (onSuccess: () => void) => {
              setsuccessCallback(() => () => onSuccess());
              if (articleTrackingItem.id) {
                setArticleId(articleTrackingItem.id);
                setShowEditDraftModal(true);
              }
            },
            tooltipCaption: t('article.flowers.editDraft'),
          } : undefined,
          viewPrintable: canViewPrintable(articleTrackingItem)
            ? {
              icon: <CustomIcon icon={OtherTypes.PRINT} />,
              onClick: () => {
                if (articleTrackingItem.id && articleTrackingItem.erpId) {
                  setArticleId(articleTrackingItem.id);
                  setShowPrintModal(true);
                }
              },
              tooltipCaption: t('common.print'),
            }
            : undefined,
          acceptFlowersRequest: canAcceptFlowersRequest(articleTrackingItem)
            ? {
              icon: <CustomIcon icon={OtherTypes.ACCEPT_FLOWERS_REQUEST} />,
              onClick: (onSuccess: () => void) => {
                setsuccessCallback(() => () => onSuccess());
                if (articleTrackingItem.id) {
                  setArticleId(articleTrackingItem.id);
                  setShowAcceptRequest(true);
                }
              },
              tooltipCaption: t('common.accept'),
            }
            : undefined,
          cancelFlowersRequest: canCancelRequest(articleTrackingItem)
            ? {
              icon: <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />,
              isExtra: true,
              onClick: (onSuccess: () => void) => {
                setsuccessCallback(() => () => onSuccess());
                if (articleTrackingItem.id) {
                  setArticleId(articleTrackingItem.id);
                  setShowCancelRequest(true);
                }
              },
              tooltipCaption: t('common.cancel'),
            }
            : undefined,
          deleteFlowersRequest: canDeleteRequest(articleTrackingItem)
            ? {
              icon: <CustomIcon icon={OtherTypes.DELETE} />,
              isExtra: true,
              onClick: (onSuccess: () => void) => {
                setsuccessCallback(() => () => onSuccess());
                if (articleTrackingItem.id) {
                  setArticleId(articleTrackingItem.id);
                  setShowDeleteRequest(true);
                }
              },
              tooltipCaption: t('common.delete'),
            }
            : undefined,
          canAddArticleDisagreement: canAddArticleDisagreement(articleTrackingItem)
            ? {
              icon: <CustomIcon icon={OtherTypes.ADD_ARTICLE_DISAGREEMENT} />,
              isExtra: true,
              onClick: (onSuccess: () => void) => {
                setsuccessCallback(() => () => onSuccess());
                if (articleTrackingItem.id) {
                  setArticleId(articleTrackingItem.id);
                  setshowAddDisaggeementModal(true);
                }
              },
              tooltipCaption: t('article.addDisagreement'),
            }
            : undefined,
          showFlowerPhoto: canShowFlowersPhoto(articleTrackingItem)
            ? {
              icon: <CustomIcon icon={OtherTypes.PHOTO} />,
              onClick: () => {
                if (articleTrackingItem.id && articleTrackingItem.erpId) {
                  setArticleId(articleTrackingItem.id);
                  setshowViewFlowerPhotoModal(true);
                }
              },
              tooltipCaption: t('article.viewPhoto'),
            }
            : undefined,
        })}
        apiCall={loadArticles}
        columns={columns}
        fields={fields}
        initialValues={erpId ? { erpId } : {}}
        text={{
          search: t('qr.search'),
          title: t('article.flowers.title'),
        }}
      />
      <CancelRequestModal
        id={articleId}
        show={showCancelRequest}
        onHide={() => setShowCancelRequest(false)}
        onSuccess={successCallback}
      />
      <DeleteRequestModal
        id={articleId}
        show={showDeleteRequest}
        onHide={() => setShowDeleteRequest(false)}
        onSuccess={successCallback}
      />
      <AcceptRequestModal
        id={articleId}
        show={showAcceptRequest}
        onHide={() => {
          setShowAcceptRequest(false);
        }}
        onSuccess={successCallback}
      />
      <PrintModal
        id={articleId}
        show={showPrintModal}
        onHide={() => setShowPrintModal(false)}
      />
      <AddArticleDisagreementModal
        articleId={articleId}
        show={showAddDisaggeementModal}
        onCancel={(() => {
          setshowAddDisaggeementModal(false);
        })}
        onSuccess={() => {
          successCallback?.();
          setshowAddDisaggeementModal(false);
        }}
      />
      <EditDraftModal
        articleId={articleId}
        show={showEditDraftModal}
        onHide={onModalDraftClose}
        onSuccess={editDraftModalOnSuccess}
      />
      <ViewFlowerModal
        id={articleId}
        show={showViewFlowerPhotoModal}
        onHide={() => {
          setshowViewFlowerPhotoModal(false);
        }}
      />
    </React.Fragment>

  );
};
