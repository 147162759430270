import { FormikProps } from 'formik';
import React, { Fragment, ReactElement } from 'react';

import { FormStep } from 'components/base/StepsForm/types';

import { i18n } from 'i18n';
import { NormalizedType } from 'models/MasterService';
import { RecordStateEnum, RecordTypeEnum, SaveRecord } from 'models/Record';

import AbortionFields from './AbortionFields';
import AmputationFields from './AmputationFields';
import { billingFields } from './billingFields';
import DeathField from './DeathFields';
import DeceasedFields from './DeceasedFields';
import DeclarantFields from './DeclarantFields';
import DocumentsFields from './DocumentsFields';
import GeneralField from './GeneralFields';
import InsuranceFields from './InsuranceFields';
import { ReceptionFields } from './ReceptionFields';

const getBillingFields = billingFields;

export const recordFormInitialValues: SaveRecord = {
  abortionData: null,
  deathData: {
    approximatedDate: null,
    deathCause: null,
    deathTime: null,
    diseases: null,
    errands: null,
    medicalCertificate: null,
    pickupAddress: {
      streetNumber: null,
    },
    trialCourt: null,
  },
  deceasedData: {},
  declarantData: {
    address: null,
    cif: null,
    comment: null,
    email: null,
    firstSurname: null,
    identifier: null,
    nie: null,
    passport: null,
    phone: null,
    secondSurname: null,
  },
  insuranceData: null,
  publishCeremony: false,
  publishVigil: false,
  receptionData: null,
  state: RecordStateEnum.OPENED,
};

export const receptionSteps = (
  props: FormikProps<SaveRecord>,
  disabled?: boolean,
): ReactElement => (
  <Fragment>
    <GeneralField disabled={disabled} formikProps={props} createMode />
    <DeceasedFields disabled={disabled} formikProps={props} createMode />
    <DeclarantFields disabled={disabled} formikProps={props} createMode />
    <DeathField disabled={disabled} formikProps={props} createMode />
    {props.values.type === RecordTypeEnum.ABORTION && (
      <AbortionFields disabled={disabled} formikProps={props} createMode />
    )}
    {props.values.type === RecordTypeEnum.AMPUTATION && (
      <AmputationFields disabled={disabled} formikProps={props} createMode />
    )}
    <ReceptionFields disabled={disabled} formikProps={props} createMode />
    <InsuranceFields disabled={disabled} formikProps={props} createMode />
  </Fragment>
);

export const steps = (
  values: SaveRecord,
  recordTypes: NormalizedType,
): FormStep<SaveRecord>[] => [
  {
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => <GeneralField disabled={disabled} formikProps={props} />,
    formFields: [
      'type',
      'firstNegociator',
      'secondNegociator',
      // 'preneed',
      'publishCeremony',
      'publishVigil',
      'ageConfidential',
      'comment',
    ],
    key: 'general',
    title: i18n.t('record.steps.general'),
  },
  {
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => (
      <DeceasedFields disabled={disabled} formikProps={props} />
    ),
    formFields: [
      'deceasedData.name',
      'deceasedData.firstSurname',
      'deceasedData.secondSurname',
      'deceasedData.nickname',
      'deceasedData.identifier',
      'deceasedData.birthday',
      'deceasedData.age',
      'deceasedData.fatherName',
      'deceasedData.motherName',
      'deceasedData.gender',
      'deceasedData.nationality',
      'deceasedData.maritalStatus',
      'deceasedData.partnerName',
      'deceasedData.childrenNames',
      'deceasedData.birthAddress.city',
      'deceasedData.birthAddress.country',
      'deceasedData.birthAddress.province',
      'deceasedData.deceasedAddress.city',
      'deceasedData.deceasedAddress.country',
      'deceasedData.deceasedAddress.province',
      'deceasedData.deceasedAddress.streetName',
      'deceasedData.deceasedAddress.postalCode',
      'deceasedData.phone',
    ],
    key: 'deceased',
    title: i18n.t('record.steps.deceased'),
  },
  {
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => (
      <DeclarantFields disabled={disabled} formikProps={props} />
    ),
    formFields: [
      'declarantData.name',
      'declarantData.firstSurname',
      'declarantData.secondSurname',
      'declarantData.deceasedKinship',
      'declarantData.identifier',
      'declarantData.address.streetName',
      'declarantData.address.postalCode',
      'declarantData.phone',
      'declarantData.email',
      'declarantData.comment',
      'declarantData.address.city',
      'declarantData.address.country',
      'declarantData.address.province',
    ],
    key: 'declarant',
    title: i18n.t('record.steps.declarant'),
  },
  {
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => <DeathField disabled={disabled} formikProps={props} />,
    formFields: [
      'deathData.approximatedDate',
      'deathData.address.city',
      'deathData.address.country',
      'deathData.address.province',
      'deathData.pickupAddress.city',
      'deathData.pickupAddress.country',
      'deathData.pickupAddress.province',
      'deathData.deathDate',
      'deathData.deathTime',
      'deathData.atHome',
      'deathData.location',
      'deathData.pickupAddress.streetName',
      'deathData.pickupAddress.postalCode',
      'deathData.deathType',
      'deathData.trialCourt',
      'deathData.errands',
      'deathData.deathCause',
      'deathData.doctor',
      'deathData.diseases',
      'deathData.comment',
    ],
    key: 'death',
    title: i18n.t('record.steps.death'),
  },
  {
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => <ReceptionFields disabled={disabled} formikProps={props} />,
    formFields: [
      'receptionData.receptionType',
      'receptionData.funeraryOrigin',
      'receptionData.entryDate',
      'receptionData.entryTime',
    ],
    key: 'reception',
    title: i18n.t('record.steps.reception'),
  },
  {
    disabled:
      !values?.type
      || recordTypes[values.type]?.code !== RecordTypeEnum.ABORTION,
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => (
      <AbortionFields disabled={disabled} formikProps={props} />
    ),
    formFields: [
      'abortionData.gender',
      'abortionData.declarantIsMother',
      'abortionData.gestationWeeks',
      'abortionData.fatherName',
      'abortionData.fatherFirstSurname',
      'abortionData.fatherSecondSurname',
      'abortionData.type',
    ],
    key: 'abortion',
    title: i18n.t('record.steps.abortion'),
  },
  {
    disabled:
      !values?.type
      || recordTypes[values.type]?.code !== RecordTypeEnum.AMPUTATION,
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => (
      <AmputationFields disabled={disabled} formikProps={props} />
    ),
    formFields: ['amputationData.member'],
    key: 'amputation',
    title: i18n.t('record.steps.amputation'),
  },
  {
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => (
      <InsuranceFields disabled={disabled} formikProps={props} />
    ),
    formFields: [
      'insuranceData.hasPolicy',
      'insuranceData.companyName',
      'insuranceData.policyNumber',
      'insuranceData.renounce',
    ],
    key: 'insurance',
    title: i18n.t('record.steps.insurance'),
  },
  {
    disabled: !values?.erpId,
    fields: (
      props: FormikProps<SaveRecord>,
      disabled?: boolean,
    ): ReactElement => (
      <DocumentsFields disabled={disabled} formikProps={props} />
    ),
    formFields: [],
    key: 'documents',
    title: i18n.t('record.steps.documents'),
  },
  {
    fields: getBillingFields,
    formFields: [],
    key: 'billing',
    title: i18n.t('record.steps.billing'),
  },
];
