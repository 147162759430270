const article = {
  addDisagreement: 'Añade desacuerdo',
  addFlowers: 'Añadir flores',
  amount: 'Cantidad',
  articleDetail: 'Información del artículo',
  articleQr: 'Escanee el QR asociado al artículo',
  confirmRequest: '¿Estás seguro de que quieres enviar {{article}}: {{observations}},  al proveedor {{supplier}} para que las prepare?',
  createArticleRequestOK: 'Solicitud creada correctamente',
  createDisagreementOK: 'desacuerdo creado correctamente',
  detail: 'Detalle artículo',
  disagreementPhoto: 'Foto de desacuerdo',
  externalFlowerSender: 'Remitente',
  firstComment: 'Texto primario',
  flowers: {
    category: 'Tipo de flor',
    channels: {
      EXTERNAL: 'Flores externas',
      PROCESSING: 'Cabré Junqueras',
      RECEPTION: 'Vía recepción',
      WEB: 'Vía web',
    },
    confirmAcceptKO: 'Error aceptando pedido',
    confirmAcceptOK: 'Pedido aceptado',
    confirmAcceptRequestText: 'Seguro que quiere aceptar este pedido',
    confirmAcceptRequestTitle: 'Acceptar pedido',
    confirmCancelKO: 'Error cancelando pedido',
    confirmCancelOK: 'Pedido cancelado correctament',
    confirmCancelText: '¿Está seguro de que desea cancelar este pedido?',
    confirmCancelTitle: 'Cancelar pedido',
    confirmDeleteKO: 'Error al eliminar la comanda',
    confirmDeleteOK: 'Pedido eliminado correctamente',
    confirmDeleteText: '¿Estás seguro de que se quiere eliminar las flores del expediente? Recuerda avisar al proveedor y eliminar la línea en EKON.',
    confirmDeleteTitle: 'Eliminar pedido',
    date: 'Fecha límite de entrega',
    dedicatory: 'Dedicatoria',
    editDraft: 'Editar borrador',
    finishedStatusText: 'Estas flores ya están en sala o destino. No se pueden realizar más acciones con este pedido.',
    observations: 'Observaciones del artículo',
    pendingStatusText: 'El proveedor todavía no ha aceptado este pedido.',
    price: 'Precio sin IVA',
    printComercialTitle: 'Documento detalle comercial',
    printTitle: 'Imprimir pedido',
    showAllFlowerRequest: 'Flores expediente',
    states: {
      CANCELLED: 'Cancelado',
      DRAFT: 'Borrador',
      FINISHED: 'Flor en sala',
      IN_PROGRESS: 'Rebut pel proveïdor',
      ON_COURSE: 'Recibido por el proveedor',
      PENDING: 'Pendiente de recibir por el proveedor',
      RECEIVED: 'Flor en el carro',
    },
    statusUpdatedOk: 'Estado actualizado correctamente',
    statusUpdatedOK: 'No se pudo realizar el cambio de estado',
    time: 'Hora límite de entrega',
    title: 'Flores',
    totalFlowers: 'Total flores',
    updateDraftSuccess: 'Borrador actualizado correctamente',
  },
  needReplace: 'es necesario reemplazo',
  otherCategoryComment: 'Comentario de otras categorías',
  photo: {
    confirm: 'Confirmar fotografía',
    repeat: 'Repetir fotografía',
    take: 'Tomar fotografía',
  },
  photoReminder: 'Recuerda sacar una foto de las flores',
  requestType: 'Tipo de solicitud de flores',
  secondComment: 'Texto ceremonial',
  takeDeliveredPhoto: 'Añadir foto de las flores',
  types: {
    DEDICATORY: 'Dedicatoria',
    DELIVERY: 'Entrega',
    ERROR: 'Error',
    STATUS: 'Estado',
  },
  updateStatusOK: 'Estado actualizado con éxito',
  viewPhoto: 'Ver fotografía',
};
export default article;
